// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footerPosition {
    flex:1 1;
    bottom: 0;
    min-width: 100%;
    width: 100%;
}

.divFooterContainer {
    bottom: 0px;
    right: 0px;
    left: 0px;
}

.divWidth {
    width: 100%;
}

.divFullHeight {
    height: 100%;
    display:flex;
    flex-direction:column;
}

.divFlexDisplay {
    flex:1 1;
    display: flex;
}

.sidebar-page {
    position: relative;
    top: 50px;
    left: 100px;
    width: 1200px;
}

.containerStyle {
    overflow:hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/Navigation/index.css"],"names":[],"mappings":"AAAA;IACI,QAAM;IACN,SAAS;IACT,eAAe;IACf,WAAW;AACf;;AAEA;IACI,WAAW;IACX,UAAU;IACV,SAAS;AACb;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,QAAM;IACN,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".footerPosition {\n    flex:1;\n    bottom: 0;\n    min-width: 100%;\n    width: 100%;\n}\n\n.divFooterContainer {\n    bottom: 0px;\n    right: 0px;\n    left: 0px;\n}\n\n.divWidth {\n    width: 100%;\n}\n\n.divFullHeight {\n    height: 100%;\n    display:flex;\n    flex-direction:column;\n}\n\n.divFlexDisplay {\n    flex:1;\n    display: flex;\n}\n\n.sidebar-page {\n    position: relative;\n    top: 50px;\n    left: 100px;\n    width: 1200px;\n}\n\n.containerStyle {\n    overflow:hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
