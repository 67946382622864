import { useState } from "react";

// Pass URL
const useLogin = (url) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleGoogle = async (response) => {
        setLoading(true);
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },

            body: JSON.stringify({ credential: response.credential }),
        })
        .then((res) => res.json())
        .then((data) => {
            setLoading(false);
            if (data?.user) {
                localStorage.setItem("user", JSON.stringify(data?.user));
                localStorage.setItem("email", data?.user.email);
                localStorage.setItem("image", data?.user.picture);
                localStorage.setItem("name", data?.user.firstName);
                window.location.reload();
            } else {
                throw new Error(data?.message || data);
            }
        })
        .catch((error) => {
            setError(error?.message);
        });
    };

    return { loading, error, handleGoogle };
};

export default useLogin;