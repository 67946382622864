import React from "react";
import "./index.css";
import useCheckin from "../../hooks/useCheckin";
import CheckinData from "../../components/CheckinData";

const DailyStandUps = () => {
    var url = "https://dailybro-api.ebizmarts.com/checkinsByUser/?email=";
    url += localStorage.email + "&limit=1";
    const { data, error } = useCheckin(
        url, "", "GET"
    );

    return (
            <div className="homeStyle">
                <CheckinData data={data} error={error}></CheckinData>
            </div>
        );
};

export default DailyStandUps;