import { Panel, Col, PanelGroup, Avatar } from 'rsuite';
import './index.css';
import pandaLogo from "../../assets/panda.png";

const getDataContent = (type, data) => {
    switch (type) {
        case 'previous' :
          return data.previous_day_work;
        case 'blocker' :
            return data.blockers;
        case 'planned' :
            return data.planned_work_today;
        default:
          return '';
        }
};

const getAvatar = (data, registeredUsers) => {
    var avatar = pandaLogo;
    if (typeof registeredUsers !== 'undefined' && registeredUsers.length > 0) {
        const result = registeredUsers.find((person) => person?.email === data?.email);
        if (typeof result !== 'undefined' && result !== null) {
            avatar = result?.picture;
        } 
    } else if (data?.email === localStorage.email) {
        avatar = localStorage.image;
    }

    return avatar;
};

const getUserName = (data, registeredUsers) => {
    var userName = data?.email.replace("@ebizmarts.com", "");
    if (typeof registeredUsers !== 'undefined' && registeredUsers?.length > 0) {
        const result = registeredUsers.find((person) => person?.email === data?.email);
        if (typeof result !== 'undefined' && result !== null) {
            userName =  result?.name;
        } 
    } else if (data?.email === localStorage.email) {
        userName = localStorage.name;
    }

    return userName;
};

const shouldShowData = (type, data) => {
    var shouldShowData = true;
    if ((typeof data === 'undefined')
        || (type === 'blocker' && (data?.blockers.match("no blockers") || data?.blockers === ""))
    ) {
        shouldShowData = false;
    }

    return shouldShowData;
};

const CardData = ( props ) => (
    <Panel {...props} bordered className='headerCardStyles' >
      <div className='titleStyle' style={{textAlign:'center'}}>{props.title}</div>
      <hr></hr>
      <PanelGroup>
        {
            props.data && props.data.map( item => (
                (shouldShowData(props.type, item) === true) ?
                <Panel key={item?.id}>
                    <Col>
                        <div>
                            <Avatar className='avatarStyle' circle src={getAvatar(item, props.registeredusers?.users)} />
                            <div className='dateStyle'>{getUserName(item, props.registeredusers?.users)}</div>
                        </div>
                        <div className='dataStyle'>
                            {getDataContent(props.type, item)}
                        </div>
                    </Col>
                </Panel>
                :
                <div key={item?.id}></div>
            ))
        }
      </PanelGroup>
    </Panel>
  );


  export default CardData;  