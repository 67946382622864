import React, { useState } from "react";
import './index.css';
import { Content, DatePicker } from 'rsuite';
import DailyReport from "../../components/DailyReport";
import useCheckin from '../../hooks/useCheckin';

const Home = () => {
    const [date, setDate] = useState('');
    const { data, loading, error, registeredUsers } = useCheckin(
        "https://dailybro-api.ebizmarts.com/checkins/", date, "GET"
    );

    const DateReport = () => (
        <div className='divDateContainerStyle'>
            <DatePicker oneTap 
                placeholder="Select Date" 
                className="headerDateStyles"
                date={date}
                onSelect={(datePicker) => {
                    const dateString = new Date(datePicker.toDateString()).toLocaleDateString('en-GB', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    }).split('/').reverse().join('-');
                    setDate(dateString);
                }}
            >
            </DatePicker>
        </div>
    );

    return (
            <div className="homeStyle">
                <Content><DateReport/><DailyReport data={data} loading={loading} error={error} registeredusers={registeredUsers} /></Content>
            </div>
        );
};

export default Home;