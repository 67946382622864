// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flexboxGrid {
    padding: 40px;
}

.flexGridItem {
    width: 25%;
    min-width: 260px;
}

.loginDivStyle {
    background-color: white;
    border: 2px solid white;
    border-radius: 8px;
}

.headerDivStyle {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.imgStyle {
    width: 200px;
    height: 200px;
    align-self: center;
}

.buttonToolbarStyle {
    display: flex;
    flex-direction: column;
    align-self: center;
}

.mainStyle {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.maxWidth {
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/screens/Landing/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".flexboxGrid {\n    padding: 40px;\n}\n\n.flexGridItem {\n    width: 25%;\n    min-width: 260px;\n}\n\n.loginDivStyle {\n    background-color: white;\n    border: 2px solid white;\n    border-radius: 8px;\n}\n\n.headerDivStyle {\n    display: flex;\n    flex-direction: column;\n    margin: 20px;\n}\n\n.imgStyle {\n    width: 200px;\n    height: 200px;\n    align-self: center;\n}\n\n.buttonToolbarStyle {\n    display: flex;\n    flex-direction: column;\n    align-self: center;\n}\n\n.mainStyle {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n}\n\n.maxWidth {\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
