import { Row, Col } from 'rsuite';
import './index.css';
import CardData from '../CardData';

const DailyReport = (props) => (
  <Row>
    <Col className="contentCardStyle" md={6} sm={12}>
      <CardData title="Previous day work" data={props.data} error={props.error} registeredusers={props.registeredusers} type="previous" />
    </Col>
    <Col className="contentCardStyle" md={6} sm={12}>
      <CardData title="Planned work today" data={props.data} error={props.error} registeredusers={props.registeredusers} type="planned" />
    </Col>
    <Col className="contentCardStyle" md={6} sm={12}>
      <CardData title="Blockers" data={props.data} error={props.error} registeredusers={props.registeredusers} type="blocker" />
    </Col>
  </Row>
);


export default DailyReport;