// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footerBackgroundStyle {
    background-color: orangered;
    padding: 18px;
    font-size: 16px;
    height: 56px;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/index.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,aAAa;IACb,eAAe;IACf,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".footerBackgroundStyle {\n    background-color: orangered;\n    padding: 18px;\n    font-size: 16px;\n    height: 56px;\n    color: white;\n    white-space: nowrap;\n    overflow: hidden;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
