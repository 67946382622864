// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../node_modules/rsuite/dist/rsuite.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerStyles {
    padding: 10px;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    background: orangered;
    color: white;
    white-space: nowrap;
    overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/components/LeftMenu/index.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,qBAAqB;IACrB,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":["@import \"rsuite/dist/rsuite.css\";\n\n.headerStyles {\n    padding: 10px;\n    font-size: 22px;\n    font-weight: bold;\n    text-align: center;\n    background: orangered;\n    color: white;\n    white-space: nowrap;\n    overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
