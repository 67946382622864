import { useState, useCallback } from "react";

export default function useCheckin() {
    const [result, setresult] = useState(null);
    const [error, seterror] = useState("");

    const sendCheckinForm  = useCallback(
        async (url, dataValue, method) => {
            fetch(url, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+JSON.parse(localStorage.user).token
                },
                body: JSON.stringify(dataValue),
                })
                .then((res) => res.json())
                .then((data) => {
                    localStorage.setItem("checkinid", JSON.stringify(data?.id));
                    seterror(data.error)
                    setresult(data)
                })
                .catch((error) => {
                    console.log(error?.message || error);
                    seterror(error?.message || error);
            });
        },
        []
    );

    return {
        result,
        error,
        sendCheckinForm,
    }
}
   