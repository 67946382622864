import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import LeftMenu from "../LeftMenu";
import Landing from "../../screens/Landing";
import Home from "../../screens/Home";
import DailyStandUps from "../../screens/DailyStandUps";
import DailyFooter from "../Footer";
import { Container } from "rsuite";

const AppNavigation = () => {
    return (
        <BrowserRouter>
          <div className="divFullHeight">
            <div className="divFlexDisplay divWidth">
              <Container className="divWidth"> 
                <LeftMenu />
                <Container>
                  <Routes>
                    <Route exact path="/"/>
                    <Route path="/" exact element={<Home /> }/>
                    <Route path="/dailystandups" exact element={<DailyStandUps /> } /> 
                  </Routes>
                </Container>
              </Container>
            </div>
            <div className="divFooterContainer"> 
                <DailyFooter className="footerPosition"></DailyFooter>
            </div>
          </div>
        </BrowserRouter>
    );
};


const FirstNavigation = () => {
  const [user, setUser] = useState({});
  useEffect(() => {
    const theUser = localStorage.getItem("user");

    if (theUser && !theUser.includes("undefined")) {
        setUser(JSON.parse(theUser));
    }
  }, []);

  return user?.email ? <AppNavigation /> : <Landing />;
};

export default FirstNavigation;