import { useEffect, useState } from "react";

const useChecking = (url, date, method) => {
    const [data, setdata] = useState(null);
    const [loading, setloading] = useState(true);
    const [error, seterror] = useState("");
    const [ registeredUsers, setRegisteredUsers ] = useState(null);
    const [ errorRegisteredUsers, setErrorRegisteredUsers ] = useState("");

    if (date !== "") {
      url += "?checkinsDate=" + date;
    }
   
    useEffect(() => {
      fetch(url, {
        method: method,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer "+JSON.parse(localStorage.user).token
        }
    })
      .then((res) => res.json())
      .then((data) => {
          seterror(data.error)
          setdata(data)
          setloading(false)
      }).catch((error) => {
        console.log(error?.message || error);
        seterror(error?.message || error)
        setdata([])
        setloading(false)
      })
    }, [url, method]);

    useEffect(() => {
      fetch('https://dailybro-auth.ebizmarts.com/registered-users', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
      .then((res) => res.json())
      .then((data) => {
        setErrorRegisteredUsers(data.error)
        setRegisteredUsers(data)
      }).catch((error) => {
        console.log(error?.message || error);
        setErrorRegisteredUsers(error?.message || error);
        setRegisteredUsers([]);
      });
    },[]);

    return { data, loading, error, registeredUsers, errorRegisteredUsers };
  };
   
  export default useChecking;
