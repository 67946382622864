import React from "react";
import 'rsuite/dist/rsuite.min.css';

import Navigation from './components/Navigation';

const App = () => {
    return (
      <Navigation />
    );
};

export default App;