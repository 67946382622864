import React from "react";
import {Content, Header} from "rsuite";

const WorkingDays = () => {
    return (
        <div className="show-fake-browser sidebar-page">
            <Header>
                <h2>Working Days</h2>
            </Header>
            <Content>
            </Content>
        </div>
    );
};
export default WorkingDays;

