import React from "react";
import "./index.css";
import CheckinForm from "../CheckinForm";
import MyDailyCheckin from "../MyDailyCheckin";

const getCurrentDay = () => {
    const currDay = new Date().getDate();
    const currMonth = new Date().getMonth() + 1;
    const currYear = new Date().getFullYear();

    return currYear + "-" + currMonth + "-" + currDay;
}

const todayCheckinRegistered = (data) => {
    var isRegistered = false;
    if (typeof data !== 'undefined' && data !== null && data.length > 0) {
        const lastCheckinDate = data[0].created_at;
        const currentDate = new Date(getCurrentDay()).toLocaleDateString('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).split('/').reverse().join('-');
        const lastCheckin = new Date(lastCheckinDate).toLocaleDateString('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).split('/').reverse().join('-');
        if ( currentDate === lastCheckin) {
            isRegistered = true;
        }
    }

    return isRegistered;
}

const CheckinData = (props) => {
    if (todayCheckinRegistered(props.data)) {
        return (
            <div className="homeCheckinStyle">
                <MyDailyCheckin data={props.data} error={props.error}></MyDailyCheckin>
            </div>
        );
    } else {
        return (
            <div className="homeCheckinStyle">
                <CheckinForm></CheckinForm>
            </div>
        );
    }
};

export default CheckinData;