// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../node_modules/rsuite/dist/rsuite.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentFormGroupStyle {
    padding: 20px;
    width: 100%;
}

.hidden {
    display: none;
}

.visible {
    display: block;
    padding: 20px;
    width: 100%;
}

.labelStyle {
    text-align: center;
}

.blockerButtonStyle {
    padding: 10px;
}

.buttonStyle {
    left: 40%;
    right: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/CheckinForm/index.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,aAAa;IACb,WAAW;AACf;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,SAAS;IACT,UAAU;AACd","sourcesContent":["@import \"rsuite/dist/rsuite.css\";\n\n.contentFormGroupStyle {\n    padding: 20px;\n    width: 100%;\n}\n\n.hidden {\n    display: none;\n}\n\n.visible {\n    display: block;\n    padding: 20px;\n    width: 100%;\n}\n\n.labelStyle {\n    text-align: center;\n}\n\n.blockerButtonStyle {\n    padding: 10px;\n}\n\n.buttonStyle {\n    left: 40%;\n    right: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
