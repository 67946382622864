import React, { useCallback, useState } from "react";
import "./index.css";
import { Content, Form, Button, Input, Toggle } from 'rsuite';
import MyDailyCheckin from "../MyDailyCheckin";
import CheckIcon from '@rsuite/icons/Check';
import CloseIcon from '@rsuite/icons/Close';
import useCheckin from "../../hooks/useCheckinForm";

const canUpdateValues = (props, planned, yesterday) => {
    return (typeof props.data !== 'undefined'
        && props.data !== null
        && planned === ""
        && yesterday === ""
    );
}

const CheckinForm = (props) => {
    const [ checkinCompleted, setChekinCompleted ] = useState(null);
    const [ checked, setChecked ] = useState(false);
    const [ yesterday, setYesterday ] = useState("");
    const [ planned, setPlanned ] = useState("");
    const [ blocker, setBlocker ] = useState("");
    const [ checkinId, setCheckinId ] = useState(0);
    const { sendCheckinForm, result } = useCheckin();

    if (canUpdateValues(props, planned, yesterday)) {
        setCheckinId(props.data.id);
        setYesterday(props.data.previous_day_work);
        setPlanned(props.data.planned_work_today);
        if (!props.data.blockers.match("no blockers") && props.data.blockers !== "") {
            setChecked(true);
            setBlocker(props.data.blockers);
        }
    }

    const canSendCheckinRequest = (yesterday, planned) => {
        return ( yesterday !== ""
                && planned !== ""
        );
    };

    const checkinData = useCallback(
        async () => {
            if (canSendCheckinRequest(yesterday, planned)) {
                try {
                    var method = "POST";
                    const dataValues = {
                        "name": "string",
                        "email": localStorage.email,
                        "previous_day_work": yesterday,
                        "planned_work_today": planned,
                        "blockers": checked ? blocker : ""
                    }
                    var url = "https://dailybro-api.ebizmarts.com/checkin/";
                    if (checkinId !== 0) {
                        url += checkinId;
                        method = "PUT";
                    }
                    await sendCheckinForm(url, dataValues, method);
                } catch (error) {
                    console.log(error);
                }
            } else {
                console.log('cant send request');
            }

            return false;
        },[
            sendCheckinForm,
            yesterday,
            planned,
            blocker,
            checked,
            checkinId,
        ]
    );

    if (typeof result !== 'undefined' && result !== null && checkinCompleted === null) {
        setChekinCompleted([ result ]);
        return (
            <MyDailyCheckin data={checkinCompleted}></MyDailyCheckin>
        );
    } else if (checkinCompleted === null) {
        return (
            <Content>
                <Form fluid>
                    <Form.Group className="contentFormGroupStyle" controlId="yesterday">
                        <Input as="textarea" rows={5} placeholder="What did you complete in your previous workday?" onChange={value=>{setYesterday(value)}} defaultValue={yesterday} />
                    </Form.Group>
                    <Form.Group className="contentFormGroupStyle" controlId="today">
                        <Input as="textarea" rows={5} placeholder="What are you planning to work on today?" onChange={value=>{setPlanned(value)}} defaultValue={planned} />
                    </Form.Group>
                    <Form.Group className="contentFormGroupStyle" controlId="blokersToggle">
                        <Form.ControlLabel className="labelStyle">
                            Do you have any blockers?
                            <Toggle className="blockerButtonStyle" 
                                checkedChildren={<CheckIcon />} 
                                unCheckedChildren={<CloseIcon />} 
                                onChange={value => setChecked(value)}/>
                        </Form.ControlLabel>
                    </Form.Group>
                    <Form.Group controlId="blocker" className={` ${checked ? "visible" : "hidden"} ` }>
                        <Input id="blockerInput" as="textarea" rows={5} placeholder="Explain your blocker here" onChange={value=>{setBlocker(value)}} defaultValue={blocker}  />
                    </Form.Group>
                    <Form.Group className="contentFormGroupStyle">
                        <div>
                            <Button className="buttonStyle" appearance="primary" onClick={checkinData}>Submit</Button>
                        </div>
                    </Form.Group>
                </Form>
            </Content>
        );
    } else {
        return (
            <MyDailyCheckin data={checkinCompleted}></MyDailyCheckin>
        );
    }
};
  
  
export default CheckinForm;
