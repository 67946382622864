import React, { useState } from "react";
import "./index.css";
import { Button } from 'rsuite';
import DailyReport from "../DailyReport"
import CheckinForm from "../CheckinForm";

const MyDailyCheckin = (props) => {
    const [ editCheckin, setEditCheckin] = useState(false);

    if (editCheckin) {
        return (
            <div className="homeMyCheckinDataStyle">
                <CheckinForm data={props.data[0]} error={props.error}></CheckinForm>
            </div>
        );
    } else {
        return (
            <div className="homeMyCheckinDataStyle">
                <DailyReport data={props.data} error={props.error}></DailyReport>
                <div>
                    <Button className="buttonStyle" appearance="primary" onClick={(value) => setEditCheckin(value)}>Edit</Button>
                </div>
            </div>
        );
    }
};

export default MyDailyCheckin;