import React, { useEffect } from "react";
import "./index.css";
import useLogin from "../../hooks/useLogin";
import {
    Container,
    Content,
    ButtonToolbar,
    Panel,
    FlexboxGrid
  } from 'rsuite';
import reactLogo from "../../assets/daily-bro-logo.png";
import backgroundImage from "../../assets/daily-bro-background.jpeg";

const Landing = () => {
  const { handleGoogle, loading, error } = useLogin(
      "https://dailybro-auth.ebizmarts.com/login"
  );

  useEffect(() => {
      /* global google */
      if (window.google) {
          google.accounts.id.initialize({
              client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
              callback: handleGoogle,
          });

          google.accounts.id.renderButton(document.getElementById("loginDiv"), {
              // type: "standard",
              theme: "outline",
              // size: "small",
              text: "signin_with",
              shape: "pill",
          });
      }
  }, [handleGoogle]);

  return (
      <div style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize:'100%'}}>
      <Container id="app">
        <Content id="app">
          <FlexboxGrid justify="center" className="flexboxGrid">
            <FlexboxGrid.Item className="flexGridItem">
              <div className="loginDivStyle">
                <Panel header={<div className="headerDivStyle"><img className="imgStyle" src={reactLogo} alt="react logo" /></div>} bordered>
                  <ButtonToolbar className="buttonToolbarStyle">
                    <main className="mainStyle">
                        {error && <p style={{ color: "red" }}>{error}</p>}
                        {loading ? <div>Loading....</div> : <div id="loginDiv"></div>}
                    </main>
                  </ButtonToolbar>
                </Panel>
              </div>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Content>
      </Container>
    </div>        
  );
};

export default Landing;