import React, { useState } from "react";
import { Sidebar, Sidenav, Nav } from 'rsuite';
import './index.css';
import Home from '../../screens/Home';
import DailyStandUps from '../../screens/DailyStandUps';
import WorkingDays from "../../screens/Setup/WorkingDays"
import GearCircleIcon from '@rsuite/icons/legacy/GearCircle';
import DashboardIcon from '@rsuite/icons/Dashboard';
import PowerOffIcon from '@rsuite/icons/legacy/PowerOff';
import CalendarCheckOIcon from '@rsuite/icons/legacy/CalendarCheckO';

  const LeftMenu = () => {
    const [activeKey, setActiveKey] = useState("1");

    const logout = () => {
      localStorage.removeItem("user");
      window.location.href="/";
      window.location.reload();
    };

    const TabContent = ({ activeKey }) => {
      switch (activeKey) {
        case '1' :
          return <Home/>;
        case '2' :
          return <DailyStandUps/>;
        case "3-1":
          return <WorkingDays/>;
        default:
          return <Home/>;
      }
    };

    return (
      <>
      <div>
<Sidebar
      style={{ display: 'flex', flexDirection: 'column' }}
      collapsible
    >
      <Sidenav.Header>
        <div className="headerStyles">
          <span style={{ marginLeft: 12 }}> DailyBro</span>
        </div>
      </Sidenav.Header>
      <Sidenav appearance="subtle">
        <Sidenav.Body>
          <Nav activeKey={activeKey} onSelect={setActiveKey}>
            <Nav.Item eventKey="1" 
                      icon={<DashboardIcon style={activeKey === '1' ? {color: 'orangered'} : {color:''}} />}
                      style={activeKey === '1' ? {color: 'orangered'} : {color:''}}
            >
              Dashboard
            </Nav.Item>
            <Nav.Item eventKey="2" 
                      icon={<CalendarCheckOIcon style={activeKey === '2' ? {color: 'orangered'} : {color:''}}/>}
                      style={activeKey === '2' ? {color: 'orangered'} : {color:''}}
            >
              Check-In
            </Nav.Item>
            <Nav.Menu
              eventKey="3"
              trigger="hover"
              title="Settings"
              icon={<GearCircleIcon style={activeKey === '3-1'  ? {color: 'orangered'} : {color:''}}/>}
              placement="rightStart"
            >
              <Nav.Item eventKey="3-1" style={activeKey === '3-1' ? {color: 'orangered'} : {color:''}}>Working Days</Nav.Item>
            </Nav.Menu>
            <Nav.Item eventKey="4" icon={<PowerOffIcon style={activeKey === '5' ? {color: 'orangered'} : {color:''}}/>} onSelect={logout}>Log Out</Nav.Item>
          </Nav>
        </Sidenav.Body>
      </Sidenav>
    </Sidebar>
      </div>

      <TabContent activeKey={activeKey} />
      </>
    );
};

export default LeftMenu;