// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../node_modules/rsuite/dist/rsuite.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rs-content {
    position: relative;
    top: 10px;
}

.homeStyle {
    width: 85%;
    display: flex;
    flex-direction: column;
    position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/screens/DailyStandUps/index.css"],"names":[],"mappings":"AAEA;IACI,kBAAkB;IAClB,SAAS;AACb;;AAEA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB","sourcesContent":["@import \"rsuite/dist/rsuite.css\";\n\n.rs-content {\n    position: relative;\n    top: 10px;\n}\n\n.homeStyle {\n    width: 85%;\n    display: flex;\n    flex-direction: column;\n    position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
