// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../node_modules/rsuite/dist/rsuite.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerCardStyles {
    padding: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.titleStyle {
    font-weight: bold;
    padding: 10px;
    font-size: 22px;
    display: block;
    text-align: center;
    white-space: pre-line;
}

.avatarStyle {
    float: left;
}

.dateStyle {
    font-size: 12px;
    align-items: center;
    padding-bottom: 20px;
    padding-top: 15px;
    float: left;
    margin-left: 20px;
}

.dataStyle {
    font-size: 13px;
    display: flex;
    float: left;
    white-space: pre-line;
}
`, "",{"version":3,"sources":["webpack://./src/components/CardData/index.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,eAAe;IACf,cAAc;IACd,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,oBAAoB;IACpB,iBAAiB;IACjB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,WAAW;IACX,qBAAqB;AACzB","sourcesContent":["@import \"rsuite/dist/rsuite.css\";\n\n.headerCardStyles {\n    padding: 10px;\n    display: flex;\n    flex-direction: column;\n    position: relative;\n}\n\n.titleStyle {\n    font-weight: bold;\n    padding: 10px;\n    font-size: 22px;\n    display: block;\n    text-align: center;\n    white-space: pre-line;\n}\n\n.avatarStyle {\n    float: left;\n}\n\n.dateStyle {\n    font-size: 12px;\n    align-items: center;\n    padding-bottom: 20px;\n    padding-top: 15px;\n    float: left;\n    margin-left: 20px;\n}\n\n.dataStyle {\n    font-size: 13px;\n    display: flex;\n    float: left;\n    white-space: pre-line;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
